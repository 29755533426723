body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*,
*:after,
*:before {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex {
  display: flex;
}
.flex.wrap {
  flex-wrap: wrap;
}
.flex.center {
  justify-content: center;
}
.j-end {
  justify-content: flex-end;
}

.mar-t-a {
  margin-top: auto;
}
.mar-t-0 {
  margin-top: 0;
}
.mar-t-1 {
  margin-top: 0.5rem;
}
.mar-t-2 {
  margin-top: 2rem;
}
.mar-t-3 {
  margin-top: 3rem;
}
.mar-l-a {
  margin-left: auto;
}
.mar-l-quarter {
  margin-left: 0.25rem;
}
.mar-l-half {
  margin-left: 0.5rem;
}
.mar-l-0 {
  margin-left: 0;
}
.mar-l-1 {
  margin-left: 0.5rem;
}
.mar-l-2 {
  margin-left: 2rem;
}
.mar-l-3 {
  margin-left: 3rem;
}
.mar-r-a {
  margin-right: auto;
}
.mar-r-0 {
  margin-right: 0;
}
.mar-r-1 {
  margin-right: 0.5rem;
}
.mar-r-2 {
  margin-right: 2rem;
}
.mar-r-3 {
  margin-right: 3rem;
}
.mar-b-a {
  margin-bottom: auto;
}
.mar-b-0 {
  margin-bottom: 0;
}
.mar-b-1 {
  margin-bottom: 0.5rem;
}
.mar-b-2 {
  margin-bottom: 2rem;
}
.mar-b-3 {
  margin-bottom: 3rem;
}
.mar-a {
  margin: auto;
}
.mar-0 {
  margin: 0;
}
.mar-1 {
  margin: 0.5rem;
}

.pad-t-a {
  padding-top: auto;
}
.pad-t-0 {
  padding-top: 0;
}
.pad-t-1 {
  padding-top: 0.5rem;
}
.pad-t-2 {
  padding-top: 1rem;
}
.pad-t-3 {
  padding-top: 1.5rem;
}
.pad-l-a {
  padding-left: auto;
}
.pad-l-0 {
  padding-left: 0;
}
.pad-l-1 {
  padding-left: 0.5rem;
}
.pad-l-2 {
  padding-left: 1rem;
}
.pad-l-3 {
  padding-left: 1.5rem;
}
.pad-r-a {
  padding-right: auto;
}
.pad-r-0 {
  padding-right: 0;
}
.pad-r-1 {
  padding-right: 0.5rem;
}
.pad-r-2 {
  padding-right: 1rem;
}
.pad-r-3 {
  padding-right: 1.5rem;
}
.pad-b-a {
  padding-bottom: auto;
}
.pad-b-0 {
  padding-bottom: 0;
}
.pad-b-1 {
  padding-bottom: 0.5rem;
}
.pad-b-2 {
  padding-bottom: 1rem;
}
.pad-b-3 {
  padding-bottom: 1.5rem;
}
.pad-a {
  padding: auto;
}
.pad-0 {
  padding: 0;
}
.pad-1 {
  padding: 0.5rem;
}

.full-w {
  width: 100%;
}
.half-w {
  width: 100%;
}
.w-15p {
  width: 50%;
}

.fs-1 {
  font-size: 0.25rem;
}
.fs-2 {
  font-size: 0.5rem;
}
.fs-3 {
  font-size: 0.75rem;
}
.fs-4 {
  font-size: 1rem;
}
.fs-5 {
  font-size: 1.25rem;
}
.fs-6 {
  font-size: 1.5rem;
}
.fs-7 {
  font-size: 1.75rem;
}
.fs-8 {
  font-size: 2rem;
}

@media (min-width: 1024px) {
  .mar-t-1 {
    margin-top: 1rem;
  }
  .mar-t-2 {
    margin-top: 2rem;
  }
  .mar-t-3 {
    margin-top: 3rem;
  }
  .mar-l-quarter {
    margin-left: 0.25rem;
  }
  .mar-l-half {
    margin-left: 0.5rem;
  }
  .mar-l-1 {
    margin-left: 1rem;
  }
  .mar-l-2 {
    margin-left: 2rem;
  }
  .mar-l-3 {
    margin-left: 3rem;
  }
  .mar-r-1 {
    margin-right: 1rem;
  }
  .mar-r-2 {
    margin-right: 2rem;
  }
  .mar-r-3 {
    margin-right: 3rem;
  }
  .mar-b-1 {
    margin-bottom: 1rem;
  }
  .mar-b-2 {
    margin-bottom: 2rem;
  }
  .mar-b-3 {
    margin-bottom: 3rem;
  }
  .mar-a {
    margin: auto;
  }
  .mar-1 {
    margin: 1rem;
  }

  .pad-t-1 {
    padding-top: 1rem;
  }
  .pad-t-2 {
    padding-top: 2rem;
  }
  .pad-t-3 {
    padding-top: 3rem;
  }
  .pad-l-1 {
    padding-left: 1rem;
  }
  .pad-l-2 {
    padding-left: 2rem;
  }
  .pad-l-3 {
    padding-left: 3rem;
  }
  .pad-r-1 {
    padding-right: 1rem;
  }
  .pad-r-2 {
    padding-right: 2rem;
  }
  .pad-r-3 {
    padding-right: 3rem;
  }
  .pad-1 {
    padding: 1rem;
  }

  .half-w {
    width: 50%;
  }
  .w-15p {
    width: 15%;
  }
}

.clickable {
  cursor: pointer;
}

.text-center {
  text-align: center;
}
